<template>
<div class="main-wrapper">
  <Header/>
  <Banner name="Login" message="Login in your account"  />
    <!-- Register & Login Start -->
    <div class="section section-padding">
        <div class="container">

            <!-- Register & Login Wrapper Start -->
            <div class="register-login-wrapper">
                <div class="row align-items-center">
                    <div class="col-lg-6">

                        <!-- Register & Login Images Start -->
                        <div class="register-login-images">
                            <div class="shape-1">
                                <img src="@/assets/images/shape/shape-26.png" alt="Shape">
                            </div>


                            <div class="images">
                                <img src="@/assets/images/register-login.png" alt="Register Login">
                            </div>
                        </div>
                        <!-- Register & Login Images End -->

                    </div>
                    <div class="col-lg-6">
                        <!-- Register & Login Form Start -->
                        <div class="register-login-form">
                            <h3 class="title"><span>Account Login</span></h3>
                            <div class="form-wrapper">
                                <form @submit.prevent="">
                                    <!-- Single Form Start -->
                                    <div class="single-form">
                                        <input type="email" v-model="payload.email"  placeholder="Email">
                                    </div>
                                    <div class="single-form">
                                        <input type="password" v-model="payload.password"  placeholder="Password">
                                        <small class="pull-right mt-2">
                                            <a href="#">Forgot password?</a>
                                        </small>
                                    </div>
                                    <!-- Single Form End -->
                                    <!-- Single Form Start -->
                                    <div class="single-form">
                                        <button class="btn btn-primary btn-hover-dark w-100" @click="login">
                                            {{ loading ? 'Sending...' : 'Login' }}
                                        </button>
                                        <!-- <a class="btn btn-secondary btn-outline w-100" href="#">Create an account</a> -->
                                        <router-link :to="{name:'Register'}" class="btn btn-secondary btn-outline w-100">
                                            Signup
                                        </router-link>
                                    </div>
                                    <!-- Single Form End -->
                                </form>
                            </div>
                        </div>
                        <!-- Register & Login Form End -->

                    </div>
                </div>
            </div>
            <!-- Register & Login Wrapper End -->

        </div>
    </div>
    <!-- Register & Login End -->
    <CTA/>
  <Footer/>
</div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import Banner from '@/components/Banner.vue'
    import CTA from '@/components/CTA.vue'
import axios from 'axios';
import Swal from 'sweetalert2';
    export default{
        name:"LoginPage",
        components:{
            Header,Footer,Banner,CTA
        },
        data(){
            return {
                loading: false,
                email:"",
                password:"",
                error:null,
                payload: {
                    email: '',
                    passworfd: ''
                }
            }
        },
        methods:{
            createUser(){
            
            },
            login() {
                axios.post('https://api.bhubcollege.com/api/v1/auth/login', this.payload)
                .then(response => {
                    if(response.data.status) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Successful',
                        })
                    }
                })
                .catch(err => {
                    if(err.response) {
                        let message
                        if(err.response.status == 422 || err.response.status == 200 || err.response.status == 401 || err.response.status == 404) {
                            if(err.response.data.errors) {
                                let errors = err.response.data.errors
                                let errorList = Object.values(errors)
                                errorList.map(msg => {
                                    message = msg
                                })
                            }
                            Swal.fire({
                                icon: 'error',
                                text: err.response.data.message || message
                            })
                        }
                    }                
                })
                .finally(() => this.loading = false)
            }
        }
    }
</script>